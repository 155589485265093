<template>
  <div class="ui password">
    <header class="bb">
      <h2 class="inner-title m-b-0 header">Password</h2>
    </header>
    <div class="content">
      <div class="out-form align-form">
        <legend class="form-group-title">Change password</legend>
        <template>
          <p class="text">If you want to change your password, please click the button below and we'll send you an email with instructions</p>
          <button class="btn btn-primary action-button" type="button" v-promise-btn @click="changePasswordHandler">change password</button>
        </template>
        <modal name="changePassword" class="modal-content" height="252" width="344">
          <div class="hello-modal">
            <p class="text text-center m-t-25 m-b-40 description">We have sent you an email to change your password</p>
            <p class="text-lg text-center">
              <button class="btn btn-primary" @click="$modal.hide('changePassword')">Close</button>
            </p>
          </div>
        </modal>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { authGetters } from '@/store/modules/auth/routines'
import { resetPassword } from '@/api/authorization'

export default {
  computed: {
    ...mapGetters({
      user: authGetters.USER
    })
  },
  methods: {
    changePasswordHandler() {
      return resetPassword(this.user.email).then(
        res => {
          this.$modal.show('changePassword')
        },
        e => {
          console.log(e)
        }
      )
    }
  }
}
</script>

<style lang="scss">
@import '../../../assets/styles/swoop/variables';
.content .text {
  font-weight: normal;
}
.action-button {
  min-width: 196px;
}
.modal-content {
  .hello-modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    align-items: center;
    .description {
      height: 56px;
      width: 250px;
      letter-spacing: -0.5px;
      font-size: 20px;
      line-height: 28px;
      color: var(--color-primary-500);
      text-align: center;
    }
  }
  .btn {
    width: 134px;
  }
}
.ui.password {
  .header {
    margin: 0rem 2rem;
    font-weight: 400;
    @media only screen and (max-width: 770px) {
      margin: 1rem;
    }
  }

  .content {
    margin: 1rem 2rem;
    @media only screen and (max-width: 770px) {
      margin: 1rem;
    }
  }
}
</style>
